
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 10px;
  }
  .echart-box {
    height: 500px;
    background: #fff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 30px;
    padding: 30px;
  }
}
