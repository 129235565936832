
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 10px;
  }
  .echarts-box {
    display: flex;
    > div {
      flex: 1;
      margin: 0 10px 24px 0;
      background: #ffffff;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      height: 320px;
      padding: 24px;
    }
    > span {
      flex: 1;
    }
  }
  .city-box {
    background: #ffffff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    height: 575px;
    padding: 50px;
    margin-bottom: 24px;
    display: flex;
    // align-items: center;
    > div {
      flex: 1;
    }
    .flip-over {
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      margin-bottom: 36px;
      > div {
        width: 30px;
        height: 30px;
        background: #2f89f2;
        float: right;
        cursor: pointer;
      }
    }
    .progress-box {
      display: flex;
      margin-bottom: 24px;
      color: #999999;
      line-height: 16px;
    }
  }
}
