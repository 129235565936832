
.map-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  position: relative;
  .text {
    position: absolute;
    color: #999;
    top: 18px;
    left: 5px;
  }
}
