
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .echart-box {
    height: 500px;
    background: #fff;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 30px;
    padding: 30px;
  }
  .echarts-box {
    display: flex;
    > div {
      background: #ffffff;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      height: 500px;
      padding: 24px;
    }
    > div:nth-child(1) {
      flex: 1;
      margin: 0 30px 30px 0;
    }
    > div:nth-child(2) {
      width: 500px;
    }
    > span:nth-child(1) {
      flex: 1;
      margin-right: 30px;
    }
    > span:nth-child(2) {
      width: 500px;
    }
  }
  .table-box {
    padding: 30px;
    display: flex;
    flex-direction: column;
    .table-item {
      // flex: 1;
      display: flex;
      height: 90px;
      > div:nth-child(1) {
        width: 36px;
        // margin-right: 24px;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > div:nth-child(2) {
        flex: 1;
        padding: 10px;
        display: flex;
        overflow: hidden;
      }
      .img {
        width: 70px;
        border-radius: 8px;
        margin-right: 10px;
      }
      .text-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #86909c;
        overflow: hidden;
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .red-text {
    color: #ff1743;
  }
}
