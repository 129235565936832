
.container-box {
  box-sizing: border-box;
  height: 100%;
  background: #f7f8fc;
  display: flex;
  font-family: PingFang SC-Regular, PingFang SC;
  font-size: 14px;
  .side-nav {
    min-width: 189px;
    height: 270px;
    padding: 18px 0;
    background: #fff;
    .nav-item {
      padding-left: 56px;
      line-height: 54px;
      border-left: 4px solid #fff;
      color: #6a686f;
      cursor: pointer;
    }
    .nav-choice {
      border-left: 4px solid #f23b2f;
      color: #f23b2f;
      background: #f7f8fc;
    }
  }
  .main-content {
    flex: 1;
    padding: 50px 40px 50px 50px;
  }
}
